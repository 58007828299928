import { DocumentNode } from "graphql";
import useSWR, { Key } from "swr";
import type { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { client } from "~lib";
import { useContext } from "react";
import { BaseContext } from "~context";

export const generateKey = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>
): Key => {
  return query ? JSON.stringify([query, variables ?? {}]) : null;
};

export const useQuery = <T extends DocumentNode>(
  query: T,
  variables?: VariablesOf<T>,
  options = {}
) => {
  const { organization } = useContext(BaseContext);

  const key = generateKey(query, variables);
  const results = useSWR<ResultOf<T>, Error>(
    key,
    async () => client(organization?.id).request(query, variables),
    options
  );

  const isLoading = typeof results.data === "undefined" && !results.error;

  return { ...results, key, isLoading };
};
